<template>
  <div class="login">
    <router-link to="/">
      <div class="logoBox">
        <img class="logo" src="./../assets/auther.png" alt="">
        <span class="text">code-notification.com</span>
      </div>

    </router-link>

    <div class="login-box">
      <div class="login-form1">
        <img class="login-form1_gif" src="../assets/loginTop.jpg" alt="" />
      </div>
      <div class="login-form">

        <div class="sub-title">
          <div>
            {{ mode == 1 ? word.login.login[language] : word.login.register[language] }}
          </div>
          <div class="line"></div>
          <div v-if="mode == 1" @click="toggleMode(2)" class="toggle-btn">
            {{ word.login.register[language] }}
          </div>
          <div v-if="mode == 2" @click="toggleMode(1)" class="toggle-btn">
            {{word.login.login[language]}}
          </div>
        </div>

        <div style="min-height: 300px" v-show="mode == 1">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" label-width="0px">
            <el-form-item prop="username">
              <el-input :prefix-icon="User" v-model="loginForm.username" type="text" autocompletee="off" :placeholder="word.login.username[language]">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password" :prefix-icon="password" type="password" autocomplete="off" :placeholder="word.login.password[language]">
              </el-input>
            </el-form-item>
            <el-form-item style="width: 100%">
              <el-button :loading="loading" size="medium" type="primary" style="width: 100%" @click="handleSubmit">
                <span v-if="!loading">{{word.login.login[language] }}</span>
                <span v-else>{{ word.login.login[language] }}</span>
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <div style="min-height: 300px" v-show="mode == 2">
          <el-form ref="regionForm" :rules="regionRules" :model="regionForm" label-position="left" label-width="0px">
            <el-form-item prop="email">
              <el-input :prefix-icon="Iphone" v-model="regionForm.email" type="text" autocomplete="on" :placeholder="word.login.username[language]">
              </el-input>
            </el-form-item>
            <el-form-item prop="companyName">

              <el-input :prefix-icon="Briefcase" v-model="regionForm.companyName" style="width: 100%" auto-complete="off" :placeholder="word.login.companyName[language]">
              </el-input>

            </el-form-item>
            <el-form-item prop="password">

              <el-input :placeholder="word.login.password[language]" :prefix-icon="password" v-model="regionForm.password" style="width: 100%" type="password" auto-complete="off">
              </el-input>

            </el-form-item>
            <el-form-item prop="repeat">
              <el-input :placeholder="word.login.password1[language]" :prefix-icon="password" v-model="regionForm.repeat" style="width: 100%" type="password" auto-complete="off">

              </el-input>

            </el-form-item>

            <el-form-item style="width: 100%">
              <el-button :loading="loading" size="medium" type="primary" style="width: 100%" @click="handleReg">
                <span v-if="!loading">{{  word.login.register[language] }}</span>
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footer">
      © 2024 HEYOU-TECH 嘉兴禾优信息科技合伙企业（有限合伙）
    </div>
  </div>
</template>
    
    <script>
import word from './../assets/json/language.json'
import { ElMessage } from 'element-plus'
import { User, Key, Phone, Briefcase } from '@element-plus/icons-vue'
export default {
  name: "Login",
  data() {
    return {
      User: User,
      password: Key,
      Iphone: Phone,
      Briefcase: Briefcase,
      language: '',
      word: word,
      accountLoading: false,
      selectOptions: [],
      WhatsAppform: {},
      // 判断是否登录WhatsApp
      isWatchAppLogin: 1,
      footer: "",
      showAgreement: false,
      lang: "en",
      read: false,
      receiver: false,
      sendLoading: false,
      height: 600,
      second: 0,
      repeat: "",
      codeUrl: "",
      cookiePass: "",
      dialogVisible: false,
      loginForm: {
        username: "",
        password: "",
        code: "",
        uuid: "",
      },
      regionForm: {
        companyName: "",
        email: "",
        password: "",
        code: "",
        repeat: ""
      },
      isWatchappsPressmiss: false,
      mode: 1,
      loginRules: {},
      regionRules: {},
      loading: false,
      redirect: undefined,
      countdown: null,
      langType: 1,
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.login == 'true') {
      this.mode = 1
    } else {
      this.mode = 2
    }

    this.language = localStorage.getItem('language')
    this.loginRules = {
      username: [
        {
          required: true,
          trigger: "blur",
          message: this.word.login.username[this.language],
        },
      ],
      password: [
        {
          required: true,
          trigger: "blur",
          message: this.word.login.password[this.language],
        },
      ]
    }

    this.regionRules = {
      email: [
        {
          required: true,
          trigger: "blur",
          message: this.word.login.username[this.language]
        },
      ],
      companyName: [
        {
          required: true,
          trigger: "blur",
          message: this.word.login.companyName[this.language]
        },
      ],
      password: [{
        required: true,
        trigger: "blur",
        message: this.word.login.password[this.language]
      },

      ],
      repeat: [{
        required: true,
        trigger: "blur",
        message: this.word.login.password1[this.language]
      },

      ],
    }

  },


  methods: {
    toggleMode(mode) {
      this.mode = mode
    },

    handleReg() {
      this.$refs.regionForm.validate(async (data) => {
        if (data) {
          ElMessage({
            message: this.word.login.reg[this.language],
            type: 'success',
          })
        }
      });
    },

    // 账号表单提交
    handleSubmit() {
      this.$refs.loginForm.validate(async (data) => {
        if (data) {
          ElMessage({
            message: this.word.login.warning[this.language],
            type: 'warning',
          })
        }
      });
    },
    // 账号选择对话框取消
    handleCancle() {
      this.loading = false;
      this.dialogVisible = false;
      this.WhatsAppform = {};
      this.$store.dispatch("LogOut");
    },
  },
};
    </script>
    
    <style rel="stylesheet/scss" lang="scss">
.footer {
  position: absolute;
  bottom: 5px;
  font-size: 14px;
}
.active {
  color: black;
  font-weight: 600;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 500px;
  background-size: calc(100% - 430px) 100%;
  background-position: left;
  background-repeat: no-repeat;
  position: relative;

  .logoBox {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      font-size: 10px;
    }

    .logo {
      height: 60px;
    }
  }

  .changeLang {
    position: absolute;
    right: 3.2%;
    top: 3.7%;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
    &_foot {
      color: rgba(37, 48, 68, 0.5);
      span {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .agreement-content {
    overflow: auto;
    line-height: 1.4;
  }
  .company {
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      margin-right: 24px;
      display: block;
      height: 30px;
    }
  }
}

.formSms {
  img {
    width: 20px;
    height: 20px;
  }
}
.WhatsAppForm {
  margin-top: 30px;

  .el-form {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .el-form-item__label {
    width: auto !important;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }

  .el-form-item__content {
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
.title {
  text-align: center;
  color: #707070;
  .line {
    margin: 0 20px;
    height: 20px;
    width: 2px;
    background-color: #666;
    cursor: text;
  }
}

.whatsApps {
  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog {
    border-radius: 15px;
  }
}

.login-form {
  box-sizing: border-box;
  border-radius: 0px 25px 25px 0px;
  height: 86%;
  background: #ffffff;
  width: 35%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 25px 25px 5px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .agreenment {
    display: flex;
    align-items: baseline;
    font-size: 13px;
    margin-bottom: 20px;
    margin-top: 35px;
    > div {
      margin-left: 10px;
      line-height: 1.5;
    }
    .el-checkbox__input {
      height: 14px;
    }
    .name {
      color: #1890ff;
      cursor: pointer;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
  .sub-title {
    color: #353535;
    margin-bottom: 40px;
    font-size: 26px;
    cursor: text;
    font-weight: 600;
    position: relative;
    .line {
      width: 24px;
      height: 4px;
      border-radius: 2px;
      margin-top: 10px;
      background-color: #1890ff;
    }
    .toggle-btn {
      position: absolute;
      bottom: 10px;
      right: 20px;
      color: #1890ff;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
.login-form1 {
  border-radius: 25px 0 0 25px;
  background: url("../assets/loginBg.jpg") no-repeat !important;
  background-size: 100% 100% !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  overflow: auto;
  height: 86%;
  width: 35%;
  display: flex;
  align-content: center;
  // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
  // padding: 25px 25px 5px 25px;
  .login-form1_gif {
    width: 50%;
    height: 50%;
    margin: 5% auto 0 auto;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-box {
  display: flex;
  align-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.login-code {
  width: 33%;
  display: inline-block;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
    